/* eslint-disable max-lines */
/* eslint-disable camelcase */
import { cloneIdentifier } from 'actions/identifier';
import CustomerSimpleList from 'components/CustomerSimpleList';
import LinkLookup from 'components/LinkLookup';
import Modal from 'components/Modal';
import RuleGroupTag from 'components/RuleGroupManager/Sidebar/RuleGroupTag';
import { NEW_EDIT_ICON } from 'constants/commonIcons';
import { typeOptionsSort } from 'constants/models';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getLanguageMatcherTermsSearchQuery,
  getSelectedIdentifier,
  getSelectedIdentifierRevision,
} from 'selectors/identifier';
import { useSelector } from 'store';
import { useClampText } from 'use-clamp-text';
import { Operator, setNewValue, transformToString, Tree } from 'utils/parserTree';
import { v4 } from 'uuid';
import EditIdentifierModal from './EditIdentifierModal';

type RouterParams = { identifierId: string };

const IdentifierHeader: React.FC = () => {
  const { identifierId } = useParams<RouterParams>();

  const identifierRevision = useSelector(getSelectedIdentifierRevision);
  const identifierGroup = useSelector(getSelectedIdentifier);
  const query = useSelector(getLanguageMatcherTermsSearchQuery);

  const identifierGroups = [
    // @ts-ignore
    ...new Set(identifierGroup?.model_groups?.filter((g) => g)),
  ] as string[];

  const [isEditingIdentifier, setIsEditingIdentifier] = useState(false);
  const [isCloneIdentifierOpen, setIsCloneIdentifierOpen] = useState(false);

  const [showMore] = useState(false);

  const [descriptionRef, { clampedText: descriptionClampedText, key: descriptionKey }] =
    useClampText({
      text: `${capitalize(identifierRevision?.kind)} matcher ${
        identifierRevision?.description ? `: ${identifierRevision?.description}` : ''
      }`,
      lines: 2,
      ellipsis: 12.5,
      expanded: showMore,
    });

  const toggleIsCloneIdentifierOpen = (): void => {
    setIsCloneIdentifierOpen(!isCloneIdentifierOpen);
  };

  const handleClinkOnEdit = (): void => {
    setIsEditingIdentifier(true);
  };

  const toggleEditModal = (): void => setIsEditingIdentifier(false);

  const renderIdentifierScreen = (
    <CustomerSimpleList
      toggleShowCloneResourceModalOpen={toggleIsCloneIdentifierOpen}
      resourceId={identifierId}
      cloneResource={cloneIdentifier}
    />
  );

  const tree: Tree = {
    id: v4(),
    op: Operator.AND,
    data: [],
  };
  const newTree = setNewValue(tree, 'broad_search', query, '', true);

  if (!identifierRevision) return null;

  return (
    <header className="flex flex-col pt-4 px-4 pb-1 bg-white">
      <div className="flex flex-col justify-between gap-4 bg-white">
        <div className="flex flex-col gap-1.5">
          <div className="w-full flex flex-row justify-between items-baseline gap-2 text-body">
            <span className="font-bold break-words">
              {identifierRevision && identifierRevision.name}
            </span>
            <button
              type="button"
              className="flex-none w-5 self-center focus:outline-none"
              onClick={handleClinkOnEdit}
            >
              {NEW_EDIT_ICON('text-litlingo-primary-120')}
            </button>
          </div>
          {identifierRevision && identifierRevision.description && (
            <div className="flex flex-col h-full justify-start gap-2">
              <div ref={descriptionRef as React.LegacyRef<HTMLDivElement>} key={descriptionKey}>
                {descriptionClampedText}
              </div>
            </div>
          )}
          <div className="flex flex-col gap-1">
            <div>Associated models are in:</div>
            <div className="flex flex-no-wrap gap-1">
              {identifierGroups
                .sort((a, b) =>
                  typeOptionsSort[a as keyof typeof typeOptionsSort] >
                  typeOptionsSort[b as keyof typeof typeOptionsSort]
                    ? -1
                    : 1
                )
                .map((group) => (
                  <RuleGroupTag key={group} group={group} />
                ))}
            </div>
          </div>
        </div>
        <LinkLookup
          className="text-small self-end"
          routeName="envelope-list"
          queryParams={{
            envelopes__filters_search: transformToString(newTree),
          }}
        >
          <span className="text-litlingo-primary-120 underline">Search for Matchers</span>
        </LinkLookup>
      </div>

      {isCloneIdentifierOpen && (
        <Modal
          body={renderIdentifierScreen}
          title="Choose a customer"
          okButton
          okButtonText="Done"
          okButtonOnClick={toggleIsCloneIdentifierOpen}
          toggleShowModal={toggleIsCloneIdentifierOpen}
        />
      )}
      {isEditingIdentifier && (
        <EditIdentifierModal
          isModalOpen={isEditingIdentifier}
          toggleModalOpen={toggleEditModal}
          identifier={identifierRevision}
        />
      )}
    </header>
  );
};

export default IdentifierHeader;
